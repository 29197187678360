import {Typography} from 'antd';
import classNames from 'classnames';
import React, {memo} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import {LogoIcon} from 'components/Icons/LogoIcon';
import {isNewYearThemeEnabledSelector} from 'selectors/app/configuration/configs';

import {LogoItem, SiteTitle, Wrapper} from './styles';
import {Props} from './types';

const Logo: Props = ({className, isCollapsed}) => {
    const isNewYearThemeEnabled = useSelector(isNewYearThemeEnabledSelector);

    return (
        <Link to="/">
            <Wrapper
                align="center"
                className={classNames(className, {'new-year': isNewYearThemeEnabled})}
                isCollapsed={isCollapsed}
            >
                <LogoItem>
                    <LogoIcon/>
                </LogoItem>
                <SiteTitle>
                    <Typography.Text>
                        WFM Effrat
                    </Typography.Text>
                </SiteTitle>
            </Wrapper>
        </Link>
    );
};

export default memo<Props>(Logo);
