import {ConfigProvider, theme} from 'antd';
import classNames from 'classnames';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {isNewYearThemeEnabledSelector} from 'selectors/app/configuration/configs';
import {CookieName, cookies} from 'utils/cookies';
import {DEFAULT_LANGUAGE} from 'utils/localization/consts';
import {useLanguagesQuery} from 'utils/queries/languages/useLanguagesQuery';

import Content from './Content';
import {MENU_COLLAPSED_WIDTH, MENU_REVEALED_WIDTH} from './Content/MenuTree/consts';
import Logo from './Logo';
import {Sider, StyledArrowLeftOutlined, StyledArrowRightOutlined} from './styles';
import {Props} from './types';

const language = cookies.get(CookieName.Language);

const THEME = {algorithm: [theme.darkAlgorithm]};

const Menu: Props = ({isCollapsed, toggleMenu}) => {
    const isNewYearThemeEnabled = useSelector(isNewYearThemeEnabledSelector);

    const {data: languages, isSuccess} = useLanguagesQuery();

    useEffect(() => {
        const allowedLanguage = languages?.find(lang => lang.lang === language);

        if (!allowedLanguage && isSuccess) {
            cookies.set(CookieName.Language, DEFAULT_LANGUAGE);
            window.location.reload();
        }
    }, [languages, isSuccess]);

    const theme = isNewYearThemeEnabled ? THEME : undefined;
    const siderTheme = isNewYearThemeEnabled ? 'dark' : 'light';

    return (
        <ConfigProvider theme={theme}>
            <Sider
                className={classNames({'new-year': isNewYearThemeEnabled})}
                width={MENU_REVEALED_WIDTH}
                collapsedWidth={MENU_COLLAPSED_WIDTH}
                collapsible
                theme={siderTheme}
                collapsed={isCollapsed}
                onCollapse={toggleMenu}
                defaultCollapsed
                trigger={<Trigger collapsed={isCollapsed}/>}
            >
                <Logo isCollapsed={isCollapsed}/>
                <Content isCollapsed={isCollapsed}/>
            </Sider>
        </ConfigProvider>
    );
};

type TriggerProps = {
    collapsed?: boolean;
}

function Trigger({collapsed}: TriggerProps) {
    const isNewYearThemeEnabled = useSelector(isNewYearThemeEnabledSelector);

    if (collapsed) {
        return <StyledArrowRightOutlined className={classNames({'new-year': isNewYearThemeEnabled})}/>;
    }

    return <StyledArrowLeftOutlined className={classNames({'new-year': isNewYearThemeEnabled})}/>;
}

export default Menu;
