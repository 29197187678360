import {Menu} from 'antd';
import styled from 'styled-components';

import {SnowFall} from 'shared/ui/new-year';

export const StyledMenu = styled(Menu)`
    &.new-year {
        background: url(${SnowFall}) center top;
        background-color: #000C17;
    }
`;
