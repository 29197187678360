import {Popover} from 'antd';
import classNames from 'classnames';
import React, {memo} from 'react';
import {useSelector} from 'react-redux';

import {isNewYearThemeEnabledSelector} from 'selectors/app/configuration/configs';

import {POPOVER_OVERLAY_STYLE} from '../Settings/FooterMenu/styles';

import Content from './Content';
import Info from './Info';
import {AccountWrapper} from './styles';
import {Props} from './types';

const content = <Content/>;

const Account: Props = ({isCollapsed}) => {
    const isNewYearThemeEnabled = useSelector(isNewYearThemeEnabledSelector);

    return (
        <Popover
            overlayClassName="styled-menu-popover account-profile-popover"
            trigger="click"
            placement="rightBottom"
            content={content}
            overlayStyle={POPOVER_OVERLAY_STYLE}
        >
            <AccountWrapper className={classNames({
                'new-year': isNewYearThemeEnabled,
            })}>
                <Info isCollapsed={isCollapsed}/>
            </AccountWrapper>
        </Popover>
    );
};

export default memo<Props>(Account);
