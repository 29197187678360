import {ArrowLeftOutlined, ArrowRightOutlined} from '@ant-design/icons';
import {Layout} from 'antd';
import styled from 'styled-components';

import {colors} from 'utils/styles/colors';

export const Sider = styled(Layout.Sider)`
    && {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        height: 100vh;
        overflow: hidden;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }

    .ant-layout-sider-trigger {
        box-shadow: inset 0 1px 0 0 ${colors.dirtyWhite};
    }

    &.new-year {
        .ant-layout-sider-trigger {
            background-color: ${colors.colorMenuDarkBg};
            box-shadow: inset 0 1px 0 0 #000C17;
        }
    }
`;

export const StyledArrowRightOutlined = styled(ArrowRightOutlined)`
    &.new-year {
        color: ${colors.darkTextDisabled};
    }
`;

export const StyledArrowLeftOutlined = styled(ArrowLeftOutlined)`
    &.new-year {
        color: ${colors.darkTextDisabled};
    }
`;
