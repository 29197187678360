import {createReduxIntegration} from '@withease/redux';
import {createEvent, createStore, sample} from 'effector';
import {Action} from 'redux';

import store from 'reduxStore';

export const startReduxInterop = createEvent();

const $reduxStore = createReduxIntegration<GlobalStateType, Action, GlobalStateType>({
    reduxStore: store,
    setup: startReduxInterop,
});

export const $store = createStore<GlobalStateType | null>(null);

sample({
    clock: $reduxStore.$state,
    target: $store,
});
