import {Typography} from 'antd';
import classNames from 'classnames';
import React, {memo} from 'react';
import {useSelector} from 'react-redux';

import {isNewYearThemeEnabledSelector} from 'selectors/app/configuration/configs';

import CurrentDomain from './CurrentDomain';
import CurrentLanguage from './CurrentLanguage';
import CurrentTimezone from './CurrentTimezone';
import {ServiceInfo, MetaWrapper} from './styles';
import {Props} from './types';

const MAX_LENGTH_COLLAPSED_DOMAIN = 7;
const MAX_LENGTH_DOMAIN = 16;

const Meta: Props = ({isCollapsed}) => {
    const isNewYearThemeEnabled = useSelector(isNewYearThemeEnabledSelector);

    return (
        <MetaWrapper
            isCollapsed={isCollapsed}
            className={classNames({
                'new-year': isNewYearThemeEnabled,
            })}
        >
            <ServiceInfo
                isCollapsed={isCollapsed}
                className={classNames({
                    'new-year': isNewYearThemeEnabled,
                })}
            >
                <CurrentDomain
                    maxLength={isCollapsed ? MAX_LENGTH_COLLAPSED_DOMAIN : MAX_LENGTH_DOMAIN}
                />
                {isCollapsed
                    ? (
                        <Typography.Text>
                            (<CurrentTimezone/>)
                        </Typography.Text>
                    )
                    : (
                        <Typography.Text>
                            &nbsp;(<CurrentTimezone/> / <CurrentLanguage/>)
                        </Typography.Text>
                    )
                }
            </ServiceInfo>
        </MetaWrapper>
    );
};

export default memo(Meta);
