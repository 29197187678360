import {Typography} from 'antd';
import {CSSProperties} from 'react';
import styled from 'styled-components';

import {colors} from 'utils/styles/colors';

export const RevealedListWrapper = styled.ul`
    overflow: hidden auto;
    width: auto;

    color: rgba(0, 0, 0, 0.88);

    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 0;
    list-style: none;
    padding-inline-start: 0;
    outline: none;

    transition: background-color 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
`;

export const ItemLabel = styled(Typography.Text)`
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all .2s ease;
`;

export const RevealedItem = styled.div<{isCollapsed?: boolean; isDisabled?: boolean}>`
    display: flex;
    align-items: center;
    justify-content: ${props => props.isCollapsed ? 'center' : 'start'};

    width: auto;
    height: 40px;
    line-height: 40px;

    color: rgba(0, 0, 0, 0.88);

    &.new-year {
        color: rgba(255, 255, 255, 0.65);

        &:hover {
            background-color: rgba(255, 255, 255, 0.12);
        }
    }

    inset-inline-start: 0;
    padding-inline: ${props => props.isCollapsed ? 'calc(50% - 12px)' : '16px'};
    margin-inline: 4px;
    margin-block: 4px;

    text-overflow: clip;
    white-space: nowrap;

    border-radius: 8px;

    &:hover {
        background-color: rgba(0, 0, 0, 0.06);
        cursor: ${props => props.isDisabled ? 'not-allowed' : 'pointer'};
    }

    transition: border-color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    ${({isCollapsed}) =>
        isCollapsed
            ? `
                ${ItemLabel} {
                    width: 0;
                    opacity: 0;
                    margin-inline-start: 0;
                }
            `
            : `
                ${ItemLabel} {
                    width: auto;
                    opacity: 1;
                    margin-inline-start: 10px;
                }
            `}

    ${ItemLabel} {
        transition: width 0.3s, opacity 0.3s, margin-inline-start 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
`;

export const RevealedItemWrapper = styled.li<{isCollapsed?: boolean}>`
    position: relative;
    display: block;

    inset-inline-start: 0;

    text-overflow: clip;
    white-space: nowrap;

    min-height: 40px;
    line-height: 40px;
`;

export const POPOVER_OVERLAY_STYLE: CSSProperties = {
    width: 420,
};
