export enum Tours {
    NewPrediction = 'new_prediction',
    DayViewPlanning = 'day_view_planning',
    Nanimator = 'nanimator',
    Employees = 'employees',
    Balancing = 'balancing',
    FavoriteFilters = 'favorite_filters',
    EmployeeCardInfo = 'employee_card_info',
    EmployeeCardSchedule = 'employee_card_schedule',
    EmployeeCardScheduleShort = 'employee_card_schedule_short',
    EmployeeCardShift = 'employee_card_shift',
    EmployeeCardDiscipline = 'employee_card_discipline',
    EmployeeCardHistory = 'employee_card_history',
    DebtPaybackGlobal = 'debt_payback_global',
    DebtPaybackShiftCard = 'debt_payback_shift_card',
}

export type Tour = {
    completedSteps: string[];
    isCompleted: boolean;
}

export type ToursMap = Record<Tours, Tour>;
