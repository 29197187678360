import classNames from 'classnames';
import React, {memo, useCallback} from 'react';
import {useSelector} from 'react-redux';

import {useLocalStorage} from 'hooks/useLocalStorage';
import {isNewYearThemeEnabledSelector} from 'selectors/app/configuration/configs';
import {blackboxSelector} from 'selectors/common/meta';
import {Domains} from 'types/domains';
import {CookieName, cookies} from 'utils/cookies';
import {triggerLink} from 'utils/file';

import {RevealedItem} from '../styles';
import {ItemPropsType} from '../types';

const language = cookies.get(CookieName.Language);

const FeedbackWrapper: ItemPropsType = ({children}) => {
    const isNewYearThemeEnabled = useSelector(isNewYearThemeEnabledSelector);

    const blackbox = useSelector(blackboxSelector);
    const [domain] = useLocalStorage<Domains>('domain', Domains.Taxi);
    const redirectToForm = useCallback(() => {
        const login = blackbox?.login && blackbox?.uid?.domain ? blackbox.login.replace(`@${blackbox.uid.domain}`, '') : undefined;
        const loginForUrl = login ? `&answer_short_text_51531593=${login}` : '';

        triggerLink(`https://forms.yandex.ru/surveys/13454749.6353305c9f6e2bc88c75adf54f508a5947053a06?answer_short_text_51531635=${domain}${loginForUrl}&lang=${language}`, {
            target: '_blank',
        });
    }, [blackbox, domain]);

    return (
        <RevealedItem
            onClick={redirectToForm}
            className={classNames({
                'new-year': isNewYearThemeEnabled,
            })}
        >
            {children}
        </RevealedItem>
    );
};

export default memo<ItemPropsType>(FeedbackWrapper);
