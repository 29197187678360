import {
    CalendarOutlined,
    ScheduleOutlined,
    FileTextOutlined,
    TeamOutlined,
    FieldTimeOutlined,
    RestOutlined,
    HomeOutlined,
    ContactsOutlined,
    BarChartOutlined,
    TagsOutlined,
    LockOutlined,
    ThunderboltOutlined,
    CarryOutOutlined,
    FundViewOutlined,
} from '@ant-design/icons';
import {Typography} from 'antd';
import React, {ReactNode, CSSProperties} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {CandidateIcon} from 'components/Icons/CandidateIcon';
import {DashboardIcon} from 'components/Icons/DashboardIcon';
import {DirectoryIcon} from 'components/Icons/DirectoryIcon';
import {DataOnboarding} from 'components/OnboardingTour/utils/dom';
import {validatePermission} from 'hooks/usePermissions';
import {PATHS} from 'routes/consts';
import {Domains} from 'types/domains';
import {getDomain} from 'utils/domain';
import i18n from 'utils/localization';

import {MenuTreeType, MenuServerItemTypes, MenuServerItemType} from '../../types';

const MENU_ICONS_MAP: Record<string, ReactNode> = {
    BarChartOutlined: <BarChartOutlined/>,
    CalendarOutlined: <CalendarOutlined/>,
    TeamOutlined: <TeamOutlined/>,
    FileTextOutlined: <FileTextOutlined/>,
    DashboardIcon: <DashboardIcon/>,
    ScheduleOutlined: <ScheduleOutlined/>,
    ThunderboltOutlined: <ThunderboltOutlined/>,
    FieldTimeOutlined: <FieldTimeOutlined/>,
    HomeOutlined: <HomeOutlined/>,
    RestOutlined: <RestOutlined/>,
    TagsOutlined: <TagsOutlined/>,
    ContactsOutlined: <ContactsOutlined/>,
    CandidateIcon: <CandidateIcon/>,
    CarryOutOutlined: <CarryOutOutlined/>,
    LockOutlined: <LockOutlined/>,
    DirectoryIcon: <DirectoryIcon/>,
    FundViewOutlined: <FundViewOutlined/>,
} as const;

const currentDomain = getDomain();

export const getMenuRestrictions = (
    nanimatorDomains: Domains[],
    domainsWithStaffAbsences: Domains[],
    permissions: string[],
) => [
    {
        path: '/nanimator-candidates',
        predicate: () => {
            return nanimatorDomains.includes(currentDomain);
        },
    },
    {
        path: '/approvals/shifts',
        predicate: () => {
            return validatePermission(permissions, 'view_drafts');
        },
    },
    {
        path: '/approvals/absences',
        predicate: () => {
            return domainsWithStaffAbsences.includes(currentDomain) && validatePermission(permissions, 'view_staff_gap_import_draft');
        },
    },
    {
        path: '/approvals',
        predicate: () => {
            return domainsWithStaffAbsences.includes(currentDomain);
        },
    },
];

const ItemLabel = styled(Typography.Text)`
    color: inherit;
`;

const makeLabel = (item: MenuServerItemType) => {
    if (item.path) {
        return (
            <Link to={item.path}>
                {item.title}
                {item.sup && (
                    <sup>
                        &nbsp;{item.sup}
                    </sup>
                )}
            </Link>
        );
    } else {
        return (
            <ItemLabel>
                {item.title}
                {item.sup && (
                    <sup>
                        &nbsp;{item.sup}
                    </sup>
                )}
            </ItemLabel>
        );
    }
};

export const getMenu = (): MenuServerItemTypes => ([
    {
        path: PATHS.PREDICTIONS,
        icon: 'BarChartOutlined',
        title: i18n.print('containers.predictions.predictions'),
    },
    {
        icon: 'CalendarOutlined',
        title: i18n.print('containers.predictions.sub_menu.shifts'),
        childs: [
            {
                path: PATHS.SHIFTS.PLANNING,
                title: i18n.print('components.menu.planning'),
            },
            {
                path: PATHS.SHIFTS.OVERVIEW,
                title: i18n.print('components.menu.schedule'),
            },
        ],
    },
    {
        path: PATHS.NANIMATOR_CANDIDATES.INDEX,
        icon: 'CandidateIcon',
        title: i18n.print('components.menu.nanimator_candidates'),
    },
    {
        path: PATHS.EMPLOYEES,
        icon: 'TeamOutlined',
        title: i18n.print('components.menu.staff'),
    },
    {
        path: PATHS.REPORTS.MAIN,
        icon: 'FileTextOutlined',
        title: i18n.print('components.menu.reports'),
    },
    {
        icon: 'CarryOutOutlined',
        title: i18n.print('components.menu.approvals'),
        childs: [
            {
                title: i18n.print('containers.approvalsshifts.changing_shifts'),
                path: PATHS.APPROVALS.SHIFTS,
            },
            {
                title: i18n.print('containers.approvalsabsences.absence_conflicts'),
                path: PATHS.APPROVALS.ABSENCES,
            },
            {
                title: i18n.print('components.menu.accruals'),
                path: PATHS.APPROVALS.ACCRUALS,
            },
        ],
    },
    {
        icon: 'FundViewOutlined',
        title: i18n.print('components.menu.monitoring'),
        childs: [
            {
                title: i18n.print('containers.discipline_overview.discipline_management'),
                path: PATHS.DASHBOARD.DISCIPLINE,
            },
            {
                title: i18n.print('containers.employeediscipline.employee_discipline'),
                path: PATHS.DASHBOARD.EMPLOYEE_DISCIPLINE,
            },
            {
                title: i18n.print('containers.balancing.load_balancing'),
                path: PATHS.BALANCING,
            },
        ],
    },
    {
        icon: 'DirectoryIcon',
        title: i18n.print('containers.predictions.sub_menu.catalogs'),
        childs: [
            {
                path: PATHS.SCHEDULES,
                icon: 'ScheduleOutlined',
                title: i18n.print('components.menu.charts'),
            },
            {
                path: PATHS.SKILLS,
                icon: 'ThunderboltOutlined',
                title: i18n.print('components.menu.skills'),
            },
            {
                path: PATHS.PROJECT_ACTIVITIES,
                icon: 'FieldTimeOutlined',
                title: i18n.print('components.menu.project_activities'),
            },
            {
                path: PATHS.ABSENCES,
                icon: 'HomeOutlined',
                title: i18n.print('components.menu.absence'),
            },
            {
                path: PATHS.WORK_BREAKS,
                icon: 'RestOutlined',
                title: i18n.print('components.menu.breaks'),
            },
            {
                path: PATHS.TAGS,
                icon: 'TagsOutlined',
                title: i18n.print('components.menu.tags'),
            },
            {
                path: PATHS.CLOSED_PERIOD,
                icon: 'LockOutlined',
                title: i18n.print('containers.closedperiod.closing_the_period'),
            },
        ],
    },
    {
        isFooter: true,
        icon: 'SettingOutlined',
        title: i18n.print('common.settings'),
        itemWrapper: 'SettingsWrapper',
        path: '',
    },
    {
        isFooter: true,
        icon: 'QuestionCircleOutlined',
        title: i18n.print('components.menu.documentation'),
        itemWrapper: 'DocumentationWrapper',
        path: '',
    },
    {
        isFooter: true,
        icon: 'BugOutlined',
        title: i18n.print('components.menu.feedback'),
        itemWrapper: 'FeedbackWrapper',
        path: '',
    },
    {
        isFooter: true,
        icon: 'TrademarkCircleOutlined',
        title: i18n.print('components.menu.about_the_system'),
        itemWrapper: 'AboutServiceWrapper',
        path: '',
    },
]);

export const makeMenuTree = (
    nanimatorDomains: Domains[],
    domainsWithStaffAbsences: Domains[],
    permissions: string[],
) => {
    const menuRestrictions = getMenuRestrictions(
        nanimatorDomains,
        domainsWithStaffAbsences,
        permissions,
    );

    const prepareRoutes = (data: MenuServerItemTypes): MenuTreeType => {
        return Object.values(data).reduce<MenuTreeType>(
            (result, item, index) => {
                if (item.isFooter) {
                    return result;
                }
                if (item.childs) {
                    const child = prepareRoutes(item.childs);

                    if (child.length === 0) {
                        return result;
                    }

                    result.push({
                        icon: item.icon ? MENU_ICONS_MAP[item.icon] : undefined,
                        children: child,
                        key: item.path || index.toString(),
                        label: makeLabel(item),
                    });
                    return result;
                }

                const predicate = menuRestrictions.find(({path}) => path === item.path)?.predicate;

                if (predicate && !predicate()) {
                    return result;
                }

                const isReport = item.path === PATHS.REPORTS.MAIN;
                const dataOnboarding = isReport ? DataOnboarding.debt_payback_global('reports') : undefined;

                result.push({
                    icon: item.icon
                        ? (
                            <span data-onboarding={dataOnboarding}>
                                {MENU_ICONS_MAP[item.icon]}
                            </span>
                        ) : undefined,
                    key: item.path || index.toString(),
                    label: makeLabel(item),
                });
                return result;
            },
            [],
        );
    };

    return prepareRoutes(getMenu());
};

export const MENU_REVEALED_WIDTH = 260;
export const MENU_COLLAPSED_WIDTH = 70;

export const MENU_STYLES: CSSProperties = {
    height: 'calc(100% - 260px)',
    overflowY: 'auto',
    overflowX: 'hidden',
};
