import {createSelector} from 'reselect';

const baseSelector = (state: GlobalStateType) => state?.app?.config?.data?.configs;

export const versionSelector = createSelector(
    baseSelector,
    (state: GlobalStateType) => state.common.meta.item?.data?.version,
    (configs, version) => configs?.['wfm-frontend:version']?.version ?? version,
);

export const isNewYearThemeEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:new-year']?.enabled || false,
);

export const isApprovalsShiftsMultiselectEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:approvals-shifts-multiselect']?.enabled || false,
);

export const maxDraftsProcessingSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:maximum-drafts-processing']?.maxCount || 0,
);

export const isViolationSettingsEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:violation-settings']?.enabled || false,
);

export const isUpdateAddAbsenceFlowEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:update-add-absence-flow']?.enabled || false,
);

export const isShiftsPlanningChartsEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:shifts-planning-charts']?.enabled || false,
);

export const isAdditionalShiftDraftsEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:additional_shift_drafts']?.enabled || false,
);

export const isWorkingOutShiftTypeEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:working-out-shift-type']?.enabled || false,
);

export const isStaffingGradientEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:staffing-gradient']?.enabled || false,
);

export const isForecastCommentsEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:forecast-comments']?.enabled || false,
);

export const isAuctionDryRunEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:auction-dry-run']?.enabled || false,
);

export const isShiftsDragNDropEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:shifts-drag-n-drop']?.enabled || false,
);

export const isFixFlowAbsencesDraftsEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:fix-flow-absences-drafts']?.enabled || false,
);

export const isCreateShiftByComplexScheduleEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:create-shift-by-complex-schedule']?.enabled || false,
);

export const isShiftsPlanningDayViewSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:shifts-planning-day-view']?.enabled || false,
);

export const isClassifiersHistoryEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:classifiers-history']?.enabled || false,
);

export const isNewOperatorTimezoneEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:new-operator-timezone']?.enabled || false,
);
