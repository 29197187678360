import {Popover} from 'antd';
import classNames from 'classnames';
import React, {memo} from 'react';
import {useSelector} from 'react-redux';

import {isNewYearThemeEnabledSelector} from 'selectors/app/configuration/configs';
import i18n from 'utils/localization';

import {POPOVER_OVERLAY_STYLE, RevealedItem} from '../styles';
import {ItemPropsType} from '../types';

import Content from './Content';

const content = <Content/>;

const SettingsWrapper: ItemPropsType = ({children, isCollapsed}) => {
    const isNewYearThemeEnabled = useSelector(isNewYearThemeEnabledSelector);

    return (
        <Popover
            overlayClassName="styled-menu-popover"
            trigger="click"
            placement="rightBottom"
            title={i18n.print('common.settings')}
            content={content}
            overlayStyle={POPOVER_OVERLAY_STYLE}
        >
            <RevealedItem
                isCollapsed={isCollapsed}
                className={classNames({
                    'new-year': isNewYearThemeEnabled,
                })}
            >
                {children}
            </RevealedItem>
        </Popover>
    );
};

export default memo<ItemPropsType>(SettingsWrapper);
