import React, {lazy} from 'react';
import {Route} from 'react-router-dom';

import withSuspense from 'hocs/withSuspense';
import {PATHS} from 'routes/consts';

const AsyncDebtPaybackReportPage = withSuspense(
    lazy(() => import('./ui/debt-payback-report-page')),
);

export default (
    <React.Fragment>
        <Route path={PATHS.REPORTS.DEBT_PAYBACK} component={AsyncDebtPaybackReportPage}/>
    </React.Fragment>
);
