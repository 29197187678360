import {combine, createStore, sample} from 'effector';

import {$store} from 'shared/redux-interop';
import {UserConfigs, UserExperiments} from 'types/experiments';

const $reduxExperiments = combine(
    $store,
    state => state?.app.config.data?.experiments ?? {},
);

const $reduxConfigs = combine(
    $store,
    state => state?.app.config.data?.configs ?? {},
);

export const $experiments = createStore<UserExperiments>({});
export const $configs = createStore<UserConfigs>({});

sample({
    clock: $reduxExperiments,
    target: $experiments,
});

sample({
    clock: $reduxConfigs,
    target: $configs,
});
