import {FieldErrors} from '@filledout/core';
import {setLocale} from 'yup';

export function initializeYupLocale() {
    setLocale({
        // use constant translation keys for messages without values
        mixed: {
            default: () => {
                return {key: 'field_invalid'};
            },
            required: () => 'field_required',
            oneOf: () => 'one_of',
        },
        // use functions to generate an error object that includes the value from the schema
        number: {
            min: () => 'field_too_short',
            max: () => 'field_too_big',
        },
        string: {
            min: () => 'field_too_short',
            max: () => 'field_too_big',
        },
    });
}

// Create types with key = known error, params = known error fields
type MinError = {
    name: 'field_too_short';
    params: {
        min: number;
    };
}

type MaxError = {
    name: 'field_too_big';
    params: {
        max: number;
    };
}

type OneOfError = {
    name: 'one_of';
    params: {
        values: string[];
    };
}

type ErrorName = MinError | MaxError | OneOfError;

const ERROR_KEYS: ErrorName['name'][] = [
    'field_too_big',
    'field_too_short',
    'one_of',
];

interface GenericError extends Omit<FieldErrors[number], 'name'> {
    name: ErrorName['name'];
}

type FieldError = {
    name: 'unknown' | GenericError['name'];
    params: GenericError['params'];
}

function isKnownError(err: FieldErrors[number]): err is GenericError {
    // ErrorKeys typed so its should be fine to cast to ErrorName['name']
    return ERROR_KEYS.includes(err.name as ErrorName['name']);
}

export function getFieldError(errors: FieldErrors): FieldError[] {
    if (!errors || !Array.isArray(errors)) {
        return [];
    }

    return errors.map<FieldError>(err => {
        if (isKnownError(err)) {
            return {
                name: err.name,
                params: err.params,
            };
        }

        return {
            name: 'unknown',
            params: err.params,
        };
    });
}
