import {Tours} from 'hooks/onboarding';

type Key = Tours | 'SHARED';
type Fn<T> = (value: string, event?: boolean) => T;

const createDataString = (tour: Tours | string): Fn<string> => (value: string, event?: boolean) => {
    return `${tour}_${value}${event ? '_EVENT' : ''}`;
};

export const DataOnboarding: Record<Key, Fn<string>> = {
    SHARED: createDataString('SHARED'),
    [Tours.Employees]: createDataString(Tours.Employees),
    [Tours.Nanimator]: createDataString(Tours.Nanimator),
    [Tours.NewPrediction]: createDataString(Tours.NewPrediction),
    [Tours.Balancing]: createDataString(Tours.Balancing),
    [Tours.FavoriteFilters]: createDataString(Tours.FavoriteFilters),
    [Tours.EmployeeCardInfo]: createDataString(Tours.EmployeeCardInfo),
    [Tours.EmployeeCardSchedule]: createDataString(Tours.EmployeeCardSchedule),
    [Tours.EmployeeCardScheduleShort]: createDataString(Tours.EmployeeCardScheduleShort),
    [Tours.EmployeeCardShift]: createDataString(Tours.EmployeeCardShift),
    [Tours.EmployeeCardDiscipline]: createDataString(Tours.EmployeeCardDiscipline),
    [Tours.EmployeeCardHistory]: createDataString(Tours.EmployeeCardHistory),
    [Tours.DebtPaybackGlobal]: createDataString(Tours.DebtPaybackGlobal),
    [Tours.DebtPaybackShiftCard]: createDataString(Tours.DebtPaybackShiftCard),
    [Tours.DayViewPlanning]: createDataString(Tours.DayViewPlanning),
};
